import { UrlSearchParamKeysType } from "@/types/types.ts";

export type RouteKey = keyof typeof routes;
export type RouteName = typeof routes[keyof typeof routes];
export type RouteType = {
  name: RouteName,
  params: RouteParamsType
}
export type BaseRouteParamsType = {
  [key: string]: unknown;
};

export type RouteParamsType = BaseRouteParamsType & {
  trackId?: string;
  playlistId?: string;
  segmentId?: string;
  pointId?: string;
  preventScrollReset?: boolean;
  searchParams?: UrlSearchParamKeysType;
  _event?: string;
};

export const routes = {
  HOME: "/",
  WORKSPACES: "/workspaces",
  DASHBOARD: "/dashboard",
  CONNECTIONS: "/connections",
  LIKES: "/likes",
  SHARED: "/shared",
  LOGIN: "/login",
  LOGOUT: "/logout",
  REGISTER: "/register",
  FORGOT_PASSWORD: "/forgot-password",
  PROFILE: "/profile",
  PLAYLIST: "/playlist/:playlistId",
  PLAYLISTS: "/playlists",
  EDIT_TRACK: "/dashboard",
  // EDIT_TRACK: "/track/:trackId",
  CREATE_SEGMENT: "/track/:trackId",
  EXPORT_SEGMENTS: "/track/:trackId/selections/export",
  CREATE_POINT: "/track/:trackId",
  EDIT_POINT: "/track/:trackId",
  EDIT_SEGMENT: "/track/:trackId",
  HISTORY_BACK: "/back",
  HISTORY_FORWARD: "/forward",
} as const;

export const routeKeys = Object.keys(routes)

export const getKeyByPath = (pathValue: string): RouteName | undefined => {
  return (Object.keys(routes) as RouteName[]).find(key => routes[key as RouteKey] === pathValue);
};

export const isRoutePath = (name: string): name is RouteName => {
  return Object.keys(routes).includes(name);
}

export const getRoutePath = (
  name: RouteName | string,
  params?: RouteParamsType | null
): string => {
  const path = getKeyByPath(name);

  if (!path) {
    return '';
  }

  const parsedPath = name.replace(/:([a-zA-Z]+)/g, (_: unknown, key: string): string => {
    const paramKey = key as keyof RouteParamsType;

    return (params?.[paramKey] ?? '') as string;
  });

  const searchParams = params?.searchParams ? `?${new URLSearchParams(params.searchParams as URLSearchParams).toString()}` : '';

  return `${parsedPath}${searchParams}`;
};

export const matchUrlToRoute = (url: string): { name: RouteName | null, params: RouteParamsType } => {
  for (const [name, pathPattern] of Object.entries(routes)) {
    // Replace de route parameters met een regex-patroon
    const regexPattern = pathPattern
      .replace(/:[a-zA-Z]+/g, '([^\\/]+)')
      .replace(/\//g, '\\/');

    // Voeg een deel toe dat het '?'-teken en de tekens daarna matcht, maar niet vastlegt in de resultaten
    const regex = new RegExp(`^${regexPattern}(?:\\?(.*))?$`);

    // Test de URL tegen de regex
    const match = url.match(regex);

    if (match) {
      // Haal de parameters uit de URL op basis van de route parameters
      const params: RouteParamsType = {};
      const keys = pathPattern.match(/:([a-zA-Z]+)/g) || [];
      keys.forEach((key, index) => {
        const paramKey = key.substring(1); // Verwijder de ':' van de sleutel
        params[paramKey] = match[index + 1]; // Gebruik de matchgroepen om de parameterwaarden op te halen
      });

      // Voeg de overige zoekparameters toe aan een afzonderlijk object
      const searchParams: Record<string, string> = {};
      const urlSearchParams = new URLSearchParams(window.location.search);
      urlSearchParams.forEach((value, key) => {
        searchParams[key] = value;
      });

      // Voeg het object met de overige zoekparameters toe aan params
      params.searchParams = searchParams;

      // Geef de gevonden route en parameters terug
      return { name: routes[name as RouteKey], params };
    }
  }

  // Als er geen overeenkomende route is gevonden, geef dan null terug
  return { name: null, params: {} };
};


export const routePaths = Object.fromEntries(
  Object.keys(routes).map((key) => [
    key,
    getRoutePath(key as RouteName)
  ])
);
