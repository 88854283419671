"use client";

import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

export const FlexBox = styled(Box, {
  shouldForwardProp: () => true,
  name: 'FlexBox',
  slot: 'Root',
})`
    // css
` as typeof Box;

/*
export const FlexBox = styled(Box, {
  shouldForwardProp: () => true,
  name: 'FlexBox',
  slot: 'Root',
})((/!*{ theme }*!/) => ({
  // base styling done in theme.ts
  // display: 'flex',
  // flex: 'auto',
  // transition: 'none',
  // transform: 'none'
}));
*/
